.mg20 {
    margin-top: 10px;
}

.label {
    font-size: 12px;

    i {
        color: crimson;
        font-weight: bold;
    }
}

.input {
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
    width: 100%;
    //opacity: 0.5;
    //font-weight: bold;
    font-size: 11px;
    border: 1px solid darkgray;
}

.input:-webkit-autofill {
    border: 1px solid #ccd2db;
}

.input:-webkit-autofill:focus {
    border: 1px solid #005ca8;
}

.input:-webkit-autofill:hover {
    border: 1px solid #ccd2db;
}