@charset "UTF-8";
// Colors

// ADMIN PAGE 
//===========

//font family
$font_fam1: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

//font weight
$fw_1: 100;
$fw_2: 200;
$fw_3: 300;
$fw_4: 400;
$fw_5: 500;
$fw_6: 600;
$fw_7: 700;
$fw_8: 800;
$fw_9: 900;
$fw_10: 1000;

//font size
$font7: 7px;
$font8: 8px;
$font9: 9px;
$font10: 10px;

$font11: 11px;
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font15: 15px;
$font16: 16px;
$font17: 17px;
$font18: 18px;
$font19: 19px;
$font20: 20px;

$font21: 21px;
$font22: 22px;
$font23: 23px;
$font24: 24px;
$font25: 25px;
$font26: 26px;
$font27: 27px;
$font28: 28px;
$font29: 29px;
$font30: 30px;

$font31: 31px;
$font32: 32px;
$font33: 33px;
$font34: 34px;
$font35: 35px;
$font36: 36px;
$font37: 37px;
$font38: 38px;
$font39: 39px;
$font40: 40px;
$font41: 41px;
$font42: 42px;
$font43: 43px;




// text color
$text_black: #0e0e0e;
$text_black_1: #000000;
$text_black_2: #313131;
$text_white: #ffffff;
$text_black_3: #354052;
$text_black_4: #11141a;
$text_light_blue: #70bfff;
$text_grey: hsl(0, 0%, 55%);
$text_grey1: #74777d;
$text_blk_gry: #354052;
$text_blk_blu: #11141a;
$text_red: rgb(226, 0, 0);
$text_col_four: #303238;
$text_col_two: #11141a;

//border
$border_1: #8a8a8a;
$border_2: #d6d6db;
$border_3: hsl(0, 0%, 80%);
$border_4: #707070;
$border_5: rgba(248, 248, 249, 0.17);

$fill_border: #e2e7ee;
$fill_border_two: #bbc5d5;
$fill_border_three: #ccd2db;
$fill_border-four: #ddd;
$fill_border-five: #2684ff;
$fill_border_six: #73879f;
$fill_bg_btn: rgba(0, 92, 168, 0.16);

$antiFlashWhite: #EEF1F3;
$brightGray: #EAEDF3;
$shadowBlue: #7E8FA2;
$checkboxBorder: #929292;
$bg_light_blue: #E0EBF4;
$bg_green: #93C01C;
$bg_white: #ffffff;
$bg_blue: #EEF1F3;
$bg_title_blue: #005CA8;
$bg_light_green: #E6F0CA;
$bg_light_sel_green: #cbe090;
$bg_light_gray: #E1E5E9;
$bg_red: red;
$darkgreen: darkgreen;
$darkGray: gainsboro;
$bg_grey_1: #ebebec;

// background color
$bg_white: #ffffff;
$bg_lgt_grey1: #e0e5ec;
$bg_lgt_grey: #f8f8f9;
$bg_normal_grey: #eaedf3;
$bg_blue: #005ca8;
$bg_black: #3a3d47;
$bg_grey: #4b4d57;
$bg_grey_1: #ebebec;
$bg_grey_2: red;
$bg_red: #fe5656;
$bg_blue1: #008cff86;
$bg_blue2: #6abcff86;
$bg: rgba(0, 92, 168, 0.286);
$bg2: #757a8d;
$bg3: #002f56;
$bg4: rgba(219, 222, 236, 0.524);
$hover_bg: #005ca827;

//fill
$fill_1: #9096ac;
$fill_2: #dde2ea;
$fill_3: #80ae07;
$fill_4: #333333;
$checkGreen: #03c700;




$white: hsl(0, 100%, 99%);
$black: hsl(0, 0%, 11%);
$error: hsl(350, 100%, 56%);
$success: hsl(166, 100%, 30%);
$info: #2684ff;

$primary: hsl(233, 62%, 14%);
$primaryDark: hsl(240, 14%, 12%);
$primaryMedium: hsl(233, 38%, 20%);
$primaryLight: hsl(232, 23%, 32%);
$primaryGreyish: hsl(233, 24%, 22%);
$primaryLightText: hsla(233, 62%, 14%, 0.133);
$primaryInverted: hsl(53, 62%, 86%);

$secondary: hsl(49, 87%, 50%);
$secondaryLight: hsl(51, 100%, 50%);
$secondaryDark: hsl(37, 100%, 50%);

$facebookBlue: #62a7c8;

$accent: hsl(351, 100%, 61%);
$accentLight: hsl(351, 100%, 76%);
$accentMedium: hsl(351, 80%, 61%);
$accentDark: hsl(351, 100%, 48%);
$accentInverted: hsl(171, 100%, 39%);

$grey: hsl(199, 11%, 46%);
$greyDark: hsl(240, 8%, 23%);
$greyMediumDark: hsl(0, 0%, 40%);
$greyMediumLight: hsl(0, 0%, 75%);
$greyLight: hsl(197, 9%, 85%);
$greyBluish: hsl(240, 29%, 89%);
$greyBluishDark: hsl(232, 17%, 56%);
$greyFont: #62727a;

// Utils

$animate: all 0s linear;

$fontBold: 600;
$fontBolder: 800;
$fontBoldest: 900;

// Media Queries

$breakpoints: (
  "phone-small": 380px,
  "phone": 480px,
  "phone-wide": 576px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-wide": 1024px,
  "desktop": 1248px,
  "desktop-wide": 1440px,
);

// Mixins

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type ==max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-columns {
  @include flex-center;
  flex-direction: column;
}

@mixin placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin text-shorten($numLines: 1) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: $numLines) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
  }
}

@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;

  @if $orientation =="vertical" {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  }

  @else if $orientation =="horizontal" {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  }

  @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

@mixin center-vertically {
  position: absolute;
  top: 50%;
  left: 50%;
  @include prefix(transform, translate(-50%, -50%), "webkit" "ms");
}

@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);

    @if $i and $i +1 <=length($args) and type-of(nth($args, $i + 1))==number {
      #{$o}: nth($args, $i + 1);
    }
  }

  position: $position;
}

// Positioning helpers
@mixin absolute($args: "") {
  @include position(absolute, $args);
}

@mixin fixed($args: "") {
  @include position(fixed, $args);
}

@mixin relative($args: "") {
  @include position(relative, $args);
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin animation($value) {
  -webkit-animation: $value;
  animation: $value;
}

@mixin animation-delay($value) {
  -webkit-animation-delay: $value;
  animation-delay: $value;
}

@mixin animation-direction($value) {
  -webkit-animation-direction: $value;
  animation-direction: $value;
}

@mixin animation-duration($value) {
  -webkit-animation-duration: $value;
  animation-duration: $value;
}

@mixin animation-fill-mode($value) {
  -webkit-animation-fill-mode: $value;
  animation-fill-mode: $value;
}

@mixin animation-iteration-count($value) {
  -webkit-animation-iteration-count: $value;
  animation-iteration-count: $value;
}

@mixin animation-name($value) {
  -webkit-animation-name: $value;
  animation-name: $value;
}

@mixin animation-play-state($value) {
  -webkit-animation-play-state: $value;
  animation-play-state: $value;
}

@mixin animation-timing-function($value) {
  -webkit-animation-timing-function: $value;
  animation-timing-function: $value;
}

@mixin css3-prefix($prop, $value) {
  -webkit-#{$prop}: #{$value};
  -moz-#{$prop}: #{$value};
  -ms-#{$prop}: #{$value};
  -o-#{$prop}: #{$value};
  #{$prop}: #{$value};
}

@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  box-sizing: $box-model;
}

@mixin light-hover {
  cursor: pointer;
  box-shadow: inset 0 0px 1000px 1000px rgba($white, 0.1), 0 23px 46px 0px rgba($white, 0.1);
  @include css3-prefix(transition, $animate);
}

@mixin light-active {
  cursor: pointer;
  box-shadow: inset 0 0 1000px 1000px rgba($white, 0.2), 0 23px 46px 0px rgba($white, 0.2);
  @include css3-prefix(transition, $animate);
}

@mixin dark-hover {
  cursor: pointer;
  box-shadow: inset 0 0 1000px 1000px rgba($black, 0.05), 0 23px 46px 0px rgba($black, 0.1);
  @include css3-prefix(transition, $animate);
}

@mixin dark-active {
  cursor: pointer;
  box-shadow: inset 0 0 1000px 1000px rgba($black, 0.1), 0 23px 46px 0px rgba($black, 0.2);
  @include css3-prefix(transition, $animate);
}

@mixin button-primary {
  background-color: $primaryMedium;
  border: 2px solid $primaryLight;
  @include css3-prefix(border-radius, 6px);
  color: $white;
  font-size: 16px;
  width: 100%;
  height: 50px;
  @include css3-prefix(transition, $animate);
  @include flex-center;
}

@mixin button-primary-transparent {
  background-color: transparent;
  border: 2px solid $primary;
  @include css3-prefix(border-radius, 6px);
  color: $primary;
  font-size: 16px;
  width: 100%;
  height: 50px;
  @include css3-prefix(transition, $animate);
  @include flex-center;
}

@mixin button-primary-dark {
  background-color: $primary;
  border: 2px solid $primaryMedium;
  @include css3-prefix(border-radius, 6px);
  color: $white;
  font-size: 16px;
  width: 100%;
  height: 50px;
  @include css3-prefix(transition, $animate);
  @include flex-center;
}

@mixin button-secondary {
  background-color: $secondary;
  border: 2px solid $secondary;
  @include css3-prefix(border-radius, 6px);
  color: $primary;
  font-size: 16px;
  width: 100%;
  height: 50px;
  @include css3-prefix(transition, $animate);
  @include flex-center;
}

@mixin button-accent {
  background-color: $accent;
  border: 2px solid $accent;
  @include css3-prefix(border-radius, 6px);
  color: $white;
  font-size: 16px;
  width: 100%;
  height: 50px;
  @include css3-prefix(transition, $animate);
  @include flex-center;
}


.active {
  border-bottom: 3px solid #005ca8;
  padding: 5px 0px 2px 0px;
  background-color: #005ca827 !important;
  color: #005ca8;
}

.reset_button {
  background-color: #ddd;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  color: #005ca8;
  font-weight: bold;
  float: right;
  margin-bottom: 10px;

  svg {
    font-Size: 12px;
    font-Weight: bold;
  }
}

@mixin custom-scrollbar($width: 10px, $track-color: #f1f1f1, $thumb-color: #888, $thumb-hover-color: #555) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $thumb-hover-color;
  }
}

// .card {
//   // @include flex-center;
//   background-color: $bg_lgt_grey;
//   gap: 4px;
//   font-size: 0.8rem;
//   // padding: 24px;
//   border-radius: 8px;
//   border: 1px solid $bg_lgt_grey1;
// }