@media (max-width: 768px) {
    .nav_mobile_overlay {
        background-color: black;
        position: fixed;
        opacity: 0.7;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 996;
        overflow-y: hidden;
    }

    .overflow_hidden {
        overflow: hidden;
    }
}

.contentTable {
    // background-color: gray;
    background-color: rgb(234, 237, 234);
    margin-left: 1vh;
    padding: 0px 1.8rem;
    padding-bottom: 12px;
    // height: calc(100% + 40px);
}