._loading_overlay_wrapper {
  position: inherit !important;
}

._loading_overlay_overlay {
  z-index: 9999 !important;
  position: fixed !important;
}

.feOXTU>.wrapper {
  width: 150px !important;
  height: 36px !important;
  border: 1px solid hsl(0deg, 0%, 80%) !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  color: #005ca8 !important;
  background-color: rgba(0, 92, 168, 0.11) !important;
  font-weight: 400;
  padding-left: 10px;
  vertical-align: middle;
  line-height: 30px;
}

.gXQXJO {
  min-height: 100% !important;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.lgffZm {
  flex-shrink: 0;
  margin: 0px 0px 0px 0px !important;
  fill: grey;
}

.dDDkFR>input {
  height: 100% !important;
  width: 100%;
  padding: 0px 0px 0px 0px !important;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: inherit;
  font-family: inherit;
  color: rgb(33, 33, 33);
}

.jZvqdQ {
  margin: 3px 0px 0px 0px !important;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: -10px !important;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.MuiStepLabel-alternativeLabel {
  margin-top: 0px !important;
  font-size: 10px !important;
}


.css-1hv8oq8-MuiStepLabel-label {
  margin-top: 2px !important;
  font-size: 10px !important;
}

.css-1toxriw-MuiList-root-MuiMenu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  outline: 0;
  display: flex;
  flex-direction: column;
}