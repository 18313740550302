.textfield {
    margin-top: 20px;
}

.formContainer {
    padding: 50px;
    vertical-align: auto;
    align-items: center;

    form {
        width: 100%;
    }
}