@import "../../assets/css/global.scss";

.button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: $text_white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    cursor: pointer;
}

.button:disabled,
.button[disabled] {
    // background-color: rgba(0, 128, 28, 0.3) !important;
    cursor: not-allowed;
    color: $text_white;
}