@import "../../assets/css/global.scss";

.checkbox{
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    input[type="checkbox"]{
        // accent-color: $checkGreen;
        accent-color: #005ca8;
        margin-right: 10px;
        cursor: pointer;
        outline: none;
    }
}