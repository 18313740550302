@import "./assets/css/global.scss";

// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// Core Styling

html,
body {
  @include box-sizing(border-box);
  margin: 0;
  padding: 0;
}

*,
*:after,
*:before {
  @include box-sizing(inherit);
}

body {
  font-family: "Poppins", sans-serif !important;
  color: $black;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-moz-selection {
  /* for Firefox */
  color: $primary;
  background: $secondaryLight;
}

::selection {
  color: $primary;
  background: $secondaryLight;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input {
  caret-color: $primary;
}

input:focus {
  border: 0px solid transparent;
  // border: 1px solid $secondary;
  outline: 0;
  // box-shadow: 0 0 0.25em 0.05em rgba($secondary, 1);
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  // border: 2px solid $secondary;
  outline: none;
  -webkit-text-fill-color: $primaryDark;
  -webkit-box-shadow: inset 0 0 0px 1000px $info inset !important;
  box-shadow: inset 0 0 0px 1000px $info inset !important;
  transition: background-color 5000s ease-in-out 0s;
  color: darken($greyLight, 10%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiBox-root {
  overflow: hidden !important;
}