$padding: 10px 20px;
$bg_grey_1: #ebebec;

.noti_container {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 350px;

    .heading {
      padding: $padding;
      font-weight: bolder;
      font-size: 1.1rem;
    }

    .noti_items_container {
      height: 80vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      .noti_items {
        display: flex;
        justify-items: center;
        align-items: center;
        gap: 0.5rem;
        padding: $padding;
        font-size: 12px;

        &:nth-child(odd) {
          background-color: $bg_grey_1;
        }

        .title {
          font-weight: bold;
        }
      }
    }
  }