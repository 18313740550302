// .btnClickOnMe{
//     position: absolute;
//     top: 0;
//     right: 12px;
// }

.contentClickAway{
    position: absolute;
    top: 60px;
    width: 100px;
    z-index: 999;
    ul{
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0;
        padding: 10px;
        list-style: none;
        border-radius: 6px;
        background-color: #fff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        li {
         padding: 0px 6px;
          }

        li:hover {
          background: rgb(87, 120, 158);
          color: #fff;
          cursor: pointer;
        }
        
    }
}