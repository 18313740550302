.header {
    height: 10vh;
    background-color: white;
    border-bottom: 2px solid rgb(241, 240, 240);
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0px 84px;

    .right {
        display: flex;
        align-items: center;
        gap: 12px;

        svg {
            font-size: 32px;
        }

        h1 {
            font-weight: 500;
        }

        .avatar {
            margin-left: 12px;
        }
    }

    img {
        height: 30px;
        // width: 100px;
        // position: absolute;
        // left: 300px;



        @media (max-width: 1024px) {
            height: 40px;
            left: 100px;
        }

        @media (max-width: 820px) {
            height: 20px;
            left: 10px;
        }
    }

    h1 {
        font-size: 24px;
    }
}


.test_main_container {
    display: flex;
    gap: 24px;
    width: 100%;
    padding: 0px 150px;
    justify-content: center;

    .Question_Pallete {
        padding: 18px 18px;

        .cardItems {
            height: 70%;
        }

        .q_number_card {
            margin-top: 12px;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: 0.5rem;
            min-width: auto;

            .q_num {
                background-color: white;
                font-size: medium;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 1.5rem;
                width: 1.5rem;
                color: white;
                border-radius: 4px;
                cursor: pointer;

                &.markedForReview {
                    background-color: yellow;
                    color: black;
                }

                &.attempted {
                    background-color: rgb(55, 184, 55);

                }

                &.notattempted {
                    background-color: rgb(219, 87, 25);

                }

                &.currentQuestion {
                    text-decoration: underline;
                    font-weight: bold;
                    color: rgb(255, 255, 255);
                }
            }
        }

        .q_legend_container {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            width: 200px;
        }

        .attempt,
        .missed,
        .MarkForReview {
            display: flex;
            gap: 4px;
            align-items: center;
            font-size: 14px;

            div {
                height: 12px;
                width: 12px;
            }
        }

        .attempt {
            div {
                background-color: green;
            }

        }

        .missed {
            div {
                background-color: red
            }
        }



        .MarkForReview {
            div {
                background-color: yellow;
            }
        }

        .btnContainer {
            display: flex;
            align-items: flex-end;
            margin-top: 12px;
            justify-content: center;
            width: 100%;
        }

        .btnfinalSubmit {
            outline: none;
            background-color: green;
            color: #e1e3e6;
            border: none;
            padding: 0.8rem 1.5rem;
            width: 100%;
            font-size: 16px;
            border-radius: 4px;
        }
    }
}

.test_container {
    // padding: 24px;
    width: 75%;

    .heading {
        display: flex;
        justify-content: space-between;
        padding: 16px 30px;

        h4 {
            font-weight: 500;
            padding: 0;
            margin: 0;
            font-size: 20px;
        }

    }

    hr {
        margin: 0;
        padding: 0;
    }

    .ques_main_container {
        padding: 16px 30px;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .qno_clearq_container {
            display: flex;
            justify-content: space-between;
        }

        .q_title {
            font-weight: 500;
            font-size: 20px;
        }


        .btnContainer {
            margin-top: 36px;
            display: flex;

            button {
                border-radius: 4px;
                cursor: pointer;
            }

            button:nth-child(1) {
                background-color: transparent;
                color: black;
                border: none;
                // padding: 0.8rem 1.5rem;
                // width: 1200px;
                font-size: 16px;
                text-align: left;
            }

            .prevnextContainer {
                margin-left: auto;

                .btn_prev {
                    background-color: red;
                    // outline: none;
                    background-color: transparent;
                    border: 2px solid green;
                    color: green;
                    padding: 0.8rem 1rem;
                    //  width: 100%;
                    font-size: 16px;
                    margin-right: 12px;
                    margin-left: auto;
                }

                .btn_next {
                    // outline: none;
                    background-color: green;
                    border: 2px solid green;
                    color: white;
                    padding: 0.8rem 3rem;
                    // width: 100%;
                    font-size: 16px;
                }
            }

        }





    }

    .ques_paper_items {
        // height: 20px;
        gap: 0.5rem;
        border-radius: 0.5rem;
        border-width: 1px;
        display: flex;
        border: 1px solid #e1e3e6;
        border-radius: 0.5rem;
        padding: 0.8rem;
        display: flex;
        align-items: center;
        justify-items: center;

        input {
            margin: 0;
        }
    }

    // }

}

.card {
    background-color: white;
    // height: auto;
    // width: 169px;
    // border: 1px solid  #cfd5de;
    border-radius: 8px;
    box-shadow: 0 5px 15px 0 #cdd6de;
    padding: 10px 15px;
    margin: 10px 0px 0px 0px;
    // margin-top: 15px;
}