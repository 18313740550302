.mg20 {
    margin-top: 0px;
    margin-bottom: 10px;
}

.mg20__center {
    text-align: center !important;
    margin-top: 0px;
    margin-bottom: 10px;
}


.label {
    font-size: 12px;

    i {
        color: crimson;
        font-weight: bold;
    }
}


.selectLabel {
    font-size: 16px;
}


.selectStyle {
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
    width: 100%;
    opacity: 0.5;
    font-size: 16px;
    border: 1px solid darkgray;
}

.selectStyle:-webkit-autofill {
    border: 1px solid #ccd2db;
}

.selectStyle:-webkit-autofill:focus {
    border: 1px solid #005ca8;
}

.selectStyle:-webkit-autofill:hover {
    border: 1px solid #ccd2db;
}


.input {
    background-color: #fff;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 38px;
    border-radius: 4px;
    width: 100%;
    /*opacity: 0.5;*/
    font-size: 11px;
    border: 1px solid darkgray;
    //font-weight: bold;
}

// .center:valid {
//     background-color: #ffd900;
// }

.input:-webkit-autofill {
    border: 1px solid #ccd2db;
}

.input:-webkit-autofill:focus {
    border: 1px solid #005ca8;
}

.input:-webkit-autofill:hover {
    border: 1px solid #ccd2db;
}

@media only screen and (max-width: 680px) {
    .label {
        font-size: 15px;
    }

    .input {
        font-size: 15px;
    }
}