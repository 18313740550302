@import "../../assets/css/global.scss";

.label {
    // font-size: $font12;
    border: none !important;
    // margin-left: 17px;
    i{
        color: crimson;
        font-weight: bold;
    }
}

.mg20 {
    margin-top: 10px;
}

.label {
    font-size: 12px;
}

.input {
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
    width: 100%;
    //opacity: 0.5;
    //font-weight: bold;
    font-size: $font16;
    border: 1px solid darkgray;
    //border-color: $fill_border_two;
    font-size: 11px;

    &[disabled] {
        opacity: 0.5;
        font-weight: bold;
        // Additional styling for readonly inputs
        // Add any desired styles here
    }
}

.input:-webkit-autofill {
    border: 1px solid $fill_border_three;
}

.input:-webkit-autofill:focus {
    border: 1px solid $bg_title_blue;
}

.input:-webkit-autofill:hover {
    border: 1px solid $bg_title_blue;
}

@media only screen and (max-width: 680px) {
    .label {
        font-size: 15px;
    }

    .input {
        font-size: 15px;
    }
}