@import "../../assets/css/global.scss";

.csv {
    width: max-content;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: $bg2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
}

.csv span {
    font-size: $font13;
    font-weight: $fw_6;
    color: #fff;
}

.csv svg {
    color: #fff;
}

.heading {
    width: 100%;
    height: 7vh;
    // background-color: $bg_black;
    // color: $text_black;
    // padding: 0px 20px;
}

.heading_content {
    width: 100%;
    height: 100%;
    // background-color: rgb(255, 179, 179);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.head_name {
    // color: $text_white;
    color: $text_black;
    font-weight: $fw_6;
    display: flex;
    gap: 2px;

    svg {
        cursor: pointer;
    }
}

.search {
    position: relative;
}

.search input[type=search] {
    border: none;
    width: 16vw;
    height: 35px;
    border-radius: 4px;
    padding-left: 35px;
    position: relative;
    color: $text_white;
    background-color: $bg2;
    caret-color: $text_white;

    cursor-text {
        color: $error;
    }
}

.search input[type=search]:focus {
    border: none;
}

::placeholder {
    color: $text_white;
    opacity: 0.4;
}

:-ms-input-placeholder {
    color: $text_white;
}

::-ms-input-placeholder {
    color: $text_white;
}

.search .search_icon {
    color: $text_white;
    opacity: 0.4;
    position: absolute;
    font-size: $font22;
    top: 7px;
    left: 8px;
}

.search .down {
    color: $text_white;
    opacity: 0.4;
    position: absolute;
    font-size: $font35;
    top: 0px;
    right: 5px;
}

.action {
    display: flex;
    align-items: center;
}

.action .btn {
    cursor: pointer;
}

.action button {
    display: flex;
    align-items: center;
    padding: 0px 15px;
    background-color: $bg2;
    color: $text_white;
    border: none;
    margin-right: 20px;
    height: 100%;
    height: 35px;
    border-radius: 4px;
}

.action button svg {
    color: $text_white;
}

.action button span {
    font-size: $font12;
}

.breadcrumb_actions {

    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    .btn_container {
        display: flex;
        justify-content: flex-end;
        margin-right: 0px;
        margin-top: -20px;
        gap: 12px;

        .btn_item_container {
            @include flex-center;
            cursor: pointer;
            gap: 8px;
            border: 1px solid rgb(235, 230, 230);
            padding: 4px 10px;
            font-size: 10px;
            background-color: #fff;
            border-radius: 4px;
            height: 32px;
        }
    }
}