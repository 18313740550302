/* .multiSelectContainer input {
    background: red;
    border: none;
    margin-top: 3px;
    color: blue;
} */

.custom-multiselect input::placeholder {
    color: #005ca8;
}

.custom-placeholder::placeholder {
    color: blue;
}

.custom-placeholder {
    .searchWrapper {
        #search_input {
            &::placeholder {
                color: rgb(0, 92, 168);
            }
        }
    }

}

.multiSelectContainer input {
    color: #005ca8;
}

input {
    color: "blue";
}

::placeholder {
    color: '#dd0a0a' !important;
    opacity: 1
}