@import "../../../../assets/css/global.scss";

.sideBar{
    border-bottom: 1px solid $border_5;
    // background-color: red;
    .menu{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: $font14;
        color: $text_white;
        cursor: pointer;
        margin: 0px;
        padding-top: 8px;
        padding-right: 10px;
        padding-bottom: 9px;
        padding-left:15px;
        
    }
    .menu:hover{
        background-color: $bg_blue2;
    }
    .sub_menu{
        // background: $bg3;
        p{
            margin: 0;
            color: $bg_white;
            font-size: $font12;
            padding: 8px 10px 9px 25px;
        }
        // p:hover{
        //     background-color: #002f56;
        // }
    }

    .menu_Container{
        display: flex;
        width: 100%;    
        align-items: center;
        gap: 0.25rem;
        p{
            width: 100%;
        }

        svg{
            margin-left: 0.5rem;
            
        }
    }
    .sub_menu{
        width: 100%;
    }
}
