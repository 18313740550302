@import "../../../assets/css/global.scss";

.container {
    display: flex;

}

.sidebar {
    padding: 10px;
    position: fixed;
    z-index: 999;
    left: 0;
    height: 100%;
    // background-color: rgb(76, 76, 219);
}

.card {
    // background-color: $white;
    border-radius: 8px;
    overflow: hidden;

}

.card .card_head {
    // background-color:$bg_blue;
    width: 100%;
    height: 55px;
    color: $white;
    margin-bottom: 35px;
    text-align: center;

    img {
        height: 30px;
        margin: 20px auto;
    }
}

.card_body {
    width: 100%;
    padding: 0px;
    padding-bottom: 50px;
    height: 85vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.left_bar {
    width: max-content;
    padding-right: 12px;
}

.right_bar {
    width: 100%;
    margin-left: 40px;
    margin-top: 65px;
    background-color: rgb(234, 237, 234);
}

.nav {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.nav_item .nav_link {
    font-size: $font15;
    font-weight: $fw_4;
    border-bottom: 1px solid $border_5;
}

.nav_link {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.nav_link svg {
    font-size: $font14;
}

.nav_link {
    padding: 5px 15px;
    color: $text_white;
    text-decoration: none;
}

.nav_submenu_link {
    padding: 5px 25px;
    display: block;
    text-decoration: none;
    color: $text_white;
}

.nav_submenu {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s;
    background: $bg_blue1;
}

.nav_submenu:target {
    max-height: 5rem;
}

.nav_submenu_link {
    font-size: $font13;
    font-weight: $fw_4;
    background: transparent;
}

.nav_submenu_link:hover {
    background: $bg_blue2;
}

a {
    text-decoration: none;
}

.ham_open_icon {
    display: none;
}

.logout_btn {
    display: none;
    transition: none;
    margin-left: 16px;
    margin-top: 6px;
    cursor: pointer;

    @include mq(phone-wide, max) {
        display: block;
    }
}


@media (max-width: 768px) {

    .sidebar {
        // display: none;
        left: -300px;
        // background-color: aqua;
        transition: left 0.3s;
    }

    .sidebar_active {
        left: 0 !important;
    }

    .ham_open_icon {
        @include flex-center;
        position: absolute;
        right: 10px;
        top: 20px;
        background-color: beige;
        border-radius: 50%;
        padding: 5px;
        height: 50px;
        width: 50px;
        cursor: pointer;
    }

    .right_bar {
        width: 100%;
        margin-left: 12px;
        margin-right: 24px;
        margin-top: 65px;
    }

}

.content {}