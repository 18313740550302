/* a.active p {
    background-color: #a0d4ff86;
} */

.active_submenu {
    background-color: #a0d4ff86;
}

.rotate-icon {
    transition: transform 0.2s ease-in-out;
}

.rotate-icon.rotate {
    transform: rotate(90deg);
}

.sub-menu {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: 0;
}

.sub-menu.show {
    max-height: 500px;
}