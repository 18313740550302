@import "../../assets/css/global.scss";

.filters_container {
    background-color: white;
    border-radius: 6px;
    margin-top: 18px;
    width: 400px;
    position: fixed;
    top: 50px;
    min-height: 100vh;
    right: -400px;
    transition: right 0.3s ease;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 999;

    &.open {
        right: 0px;
        transition: right 0.3s ease;
    }
}

.filters_container .head_container {
    height: 36px;
    display: flex;
    justify-items: center;
    padding: 0px 24px;
    padding-top: 12px;
    justify-content: space-between;
    font-weight: 600;

    .clear {
        color: #313131;
        cursor: pointer;

        svg {

            font-size: large;
        }

        &:hover {
            background-color: transparent;
        }
    }
}

.filters_container .head_container .head_container_item {
    display: flex;
    cursor: pointer;
    gap: 4px;

    .title {
        color: #313131;
    }
}

.filters_container hr {
    padding-top: 0;
    border: 1px solid #D6D6DB;
    width: 100%;
}

.filters_container .certificate_main_container {
    display: flex;
    padding: 0px 24px;
    padding-top: 12px;
    width: 100%;
    gap: 20px;
}

.items {
    padding-left: 24px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-right: 10px;
    padding-bottom: 10px;

    .columnItem {
        display: flex;
        cursor: pointer;
    }

    // max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 12px;
        margin: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #DBDEE2;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #5555559c;
    }
}

.filter {
    margin: 24px 0px;
    overflow-y: auto;
    height: calc(100vh - 230px);
    padding-bottom: 30px;
    padding-right: 24px;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;

    &::-webkit-scrollbar {
        width: 12px;

    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;

    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
        border: 3px solid #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .select {
        width: 100%;
        height: 36px;
        border: 1px solid $border_3;
        border-radius: 4px;
        font-size: $font16;
        color: #005ca8;
        background-color: rgba(0, 92, 168, 0.11);
        font-family: $font_fam1;
        font-weight: $fw_4;
        padding-left: 10px;
        vertical-align: middle;
        line-height: 30px;

        :focus {
            border: 0px solid $info;
            box-shadow: none;
        }
    }
}