@import "../../../assets/css/global.scss";

.header {
    width: 100%;
    // min-width: 680px;
    height: 65px;
    background-color: $bg_lgt_grey;
    padding: 10px 35px 10px 15px;
    position: fixed;
    z-index: 666;
}

.header .content {
    // width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.header .content .left {
    //   height: 110px;
    display: flex;
    align-items: center;
}

.header .content .left img {
    height: 30px;
}

.header .content .left h4 {
    margin: 0;
    margin-left: 35px;
    color: $text_black;
    font-size: $font18;
}



.header .content .right {
    // height: 110px;
    display: flex;
    align-items: center;
}

.header .content .right .bell {
    position: relative;
    margin-top: 10px;
}

.header .content .right .bell svg {
    color: $fill_1;
    font-size: $font32;
}

.header .content .right .bell .bell_dot {
    height: 9px;
    width: 9px;
    border: 1px solid $bg_lgt_grey;
    background-color: $bg_red;
    border-radius: 10px;
    position: absolute;
    top: 5px;
    left: 18px;
}

.header .content .right .profile {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header .content .right .profile svg {
    font-size: $font42;
    color: $bg_blue;
    margin-right: 10px;
}

.header .content .right .profile p {
    margin: 0;
    padding: 0;
    font-size: $font17;
    font-weight: $fw_6;
    color: $text_black_1;
}

// .overflow_hidden {
//     overflow: auto;
// }

// @media (max-width: 768px) {
//     .overflow_hidden {
//         overflow: hidden;
//         background: #000;
//     }
// }