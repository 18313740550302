.contentClickAway {
  position: absolute;
  top: 60px;
  right: 8rem;
  z-index: 999;


  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 80%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
}